.box-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .title-footer {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #363C4A;
    }
    .box-link {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        a {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-decoration-color:#363C4A;
            color: #363C4A;
            cursor: pointer;
            &:hover {
                color: #1976d2;
                text-decoration-color:#1976d2;
            }
        }
    }
}

.box-footer-not-link {
    .title-footer {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #363C4A;
    }
}

@media screen and (max-width: 790px) {
    .box-footer {
        min-height: 110px;
        display: block;
        .title-footer {
            text-align: center;
        }
    }
    .box-footer-not-link {
        display: block;
        .title-footer {
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .box-link {
        flex-direction: column;
        gap: 1rem !important;
        padding-bottom: 10px;
    }
}