.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 22px;
  box-shadow: 2px 2px 10px rgba(3, 3, 33, 0.3);
  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .btn-close:hover {
    cursor: pointer;
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #030321;
    margin-bottom: 20px;
    margin-top: unset;
  }
  .des {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #030321;
    margin-bottom: 25px;
  }
  .confirm {
    padding: 5px 25px;
    border-radius: 30px;
    background-color: #1d439b;
    color: #fff;
    margin-bottom: 10px;
    height: 52px;
    font-size: 16px;
    text-transform: none;
  }
  .cancel {
    font-size: 16px;
    height: 52px;
    border-radius: 30px;
    color: #030321;
    padding: 5px 25px;
    border: 1px solid #cccccc;
    text-transform: none;
  }
  .confirm.delete {
    background-color: #c93b49;
  }
  .confirm.delete:hover {
    background-color: #c93b49;
    opacity: 0.8;
  }
  .cancel:hover {
    border: 1px solid #cccccc;
    background-color: #fafafa;
    opacity: 0.8;
  }
}

.modal-box:focus-visible {
  outline: none;
}

@media screen and (max-width: 650px) {
  .modal-box {
    width: 350px;
  }
}
