.box-bulk {
  background-color: #d9d9d9;
  padding-inline: 15px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #363c4a;
  }
  .count {
    padding: 0 5px;
  }
}

.box-bulk.selected {
  background-color: #1d439b;
  cursor: pointer;
  p {
    color: #fff;
  }
}

.box-bulk-winery {
  background-color: #d9d9d9;
  padding-inline: 15px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #363c4a;
  }
  .count {
    padding: 0 5px;
  }
}

.box-bulk-winery.selected {
  background-color: #1d439b;
  cursor: pointer;
  p {
    color: #fff;
  }
}

.menu-item {
  p {
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #030321;
  }
}

@media screen and (max-width: 650px) {
  .box-bulk {
    width: 100%;
    margin-top: 17px;
  }
}