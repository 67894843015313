.modal-toast-bulk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 22px;
  box-shadow: 2px 2px 10px rgba(3, 3, 33, 0.3);

  .btn-close {
    background-color: #FFFFFF;
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }

  .btn-close:hover {
    cursor: pointer;
  }

  .confirm {
    padding: 5px 25px;
    border-radius: 30px;
    background-color: #1d439b;
    color: #fff;
    margin-bottom: 10px;
    height: 52px;
    font-size: 16px;
    text-transform: none;
    margin-top: 26px;
  }

  .text-header-success {
    display: flex;
    align-items: center;
    height: 49px;
    padding-left: 15px;
    background-color: #D6EEE8;
    padding: 15px 31px;
    border: 1px solid #20C198;
    border-radius: 8px;
    color: #030321;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 500;
  }

  .text-header-error {
    display: flex;
    align-items: center;
    height: 49px;
    padding-left: 15px;
    background-color: #F0DDDE;
    padding: 15px 25px;
    border: 1px solid #CE4D59;
    border-radius: 8px;
    color: #030321;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 500;
  }

  .error-container {
    margin-top: 15px;

    .error-content {
      background-color: #F6F6F6;
      border: 1px solid #CCCCCC;
      border-radius: 8px;
      box-shadow: unset !important;
      .MuiTypography-root {
        color: #030321;
        font-size: 16px;
        line-height: 18.75px;
        font-weight: 500 !important;
      }
    }

    .container-header-error {
      height: 49px !important;
      min-height: unset;
      padding: 15px 25px;
      .content-header-error {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .MuiAccordionSummary-content {
        margin-block: 0px !important;
      }
    }

    .Mui-expanded {
      .container-header-error {
        border-radius: unset;
        border-bottom: 1px solid #CCCCCC;
      }
    }

    .error-content-body {
      max-height: 285px;
      overflow-y: auto;
      margin-top: 10px;
      margin-right: 10px;

      .MuiAccordionDetails-root {
        height: 51px;
        padding: 15px 25px;
      }
      .content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .first-content {
        padding: 5px 25px;
        height: 41px;
      }
      .last-content {
        border-bottom: unset;
        height: 41px;
      }
    }
  }
}

.modal-toast-bulk:focus-visible {
  outline: none;
}

@media screen and (max-width: 650px) {
  .modal-toast-bulk {
    width: 350px;
  }
}