::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #BBBBBB;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #BBBBBB;
}

::-webkit-scrollbar-track {
  background-color: #D9D9D9;
  border-radius: 4px;
}