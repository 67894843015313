.popup-language {
  width: 390px;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 20px;
  box-shadow: 2px 2px 10px rgba(3, 3, 33, 0.3);
  height: 277px;
  
  .confirm {
    padding: 5px 25px;
    border-radius: 30px;
    background-color: #1d439b;
    color: #fff;
    margin-bottom: 10px;
    height: 52px;
    font-size: 16px;
    text-transform: none;
    margin-top: 26px;
  }
}

.popover-language {
  border-radius: 16px !important;
  margin-top: 105px;
}

.user-content {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;

  color: #030321;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .avatar-name {
    width: 52px !important;
    height: 52px !important;
    background-color: #999999;
  }
}

.popup-language:focus-visible {
  outline: none;
}

@media screen and (max-width: 650px) {
  .popup-language {
    width: 350px;
  }
}
