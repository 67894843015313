.modal-box {
    padding: 25px !important;

    .box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-terms {
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            color: #030321;

        }
    }
   
    .box-container {
        border: 1px solid #BBBBBB;
        border-radius: 8px;
        padding: 10px 0 10px 10px;
        background: #F6F6F6;
        .box-content {
            color: #363C4A;
            overflow: hidden;
            height: 300px;
            overflow-y: scroll;
            margin-right: 3px;

            .title-desc {
                font-size: 18px;
                font-weight: 600;
                line-height: 21px;
                margin: 0;
            }
    
            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
            }

            .header-text-terms {
                margin-top: 0;
            }
        }
    }

    .box-checkbox {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;

        label {
            color: #363C4A;
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
        }
    }
    .confirm {
        margin-bottom: 20px;
    }
    .confirm-disable {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 25px;
        border-radius: 30px;
        background-color: #D9D9D9 !important;
        color: #363C4A !important;
        margin-bottom: 20px;
        height: 52px;
        font-size: 16px;
        text-transform: none;
        cursor: not-allowed;
    }

    .title-sigOut {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #363C4A;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
        width: 20%;
        margin: 0 auto;
        text-decoration-color: #363C4A !important;

        &:hover {
            color: #1976d2;
            text-decoration-color: #1976d2 !important;
        }
    }
    .title-privacy {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #363C4A;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
        width: 30%;
        margin: 0 auto;
        margin-top: 10px;
        text-decoration-color: #363C4A !important;

        &:hover {
            color: #1976d2;
            text-decoration-color: #1976d2 !important;
        }
    }


}

@media screen and (max-width: 650px) {
    .title-terms {
        font-size: 20px !important;
    }
    .title-sigOut {
        width: 30% !important;
    }
    .title-privacy {
        width: 45% !important;
    }
}