body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  font-family: "Roboto" !important;
}
.MuiContainer-root {
  padding-top: 25px !important;
}

@media screen and (max-width: 990px) {
  .MuiContainer-root {
    padding-inline: 20px !important;
  }
}

@media screen and (max-width: 650px) {
  .MuiContainer-root {
    padding-inline: 16px !important;
  }
}